exports.components = {
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-software-architecture-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/software_architecture/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-software-architecture-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-index-js": () => import("./../../../src/pages/links/index.js" /* webpackChunkName: "component---src-pages-links-index-js" */),
  "component---src-pages-photos-index-js": () => import("./../../../src/pages/photos/index.js" /* webpackChunkName: "component---src-pages-photos-index-js" */),
  "component---src-pages-reader-index-js": () => import("./../../../src/pages/reader/index.js" /* webpackChunkName: "component---src-pages-reader-index-js" */),
  "component---src-pages-sharings-index-js": () => import("./../../../src/pages/sharings/index.js" /* webpackChunkName: "component---src-pages-sharings-index-js" */),
  "component---src-templates-album-js": () => import("./../../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */)
}

